import { render, staticRenderFns } from "./DealOrder.vue?vue&type=template&id=cc47a3c0&"
import script from "./DealOrder.vue?vue&type=script&lang=js&"
export * from "./DealOrder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports